<template>
  <div>
    <div v-if="detail">
      <div v-if="raw">
        <code lang="html">
          {{ detail.html }}
        </code>
      </div>
      <div v-else>
        <div v-html="detail.html"></div>
      </div>
    </div>
    <div v-if="notFound">
      <span class="error--text">Email HTML deleted or does not exists</span>
    </div>
  </div>
</template>

<script>
import Email from '../../../services/email';
const email = new Email();
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    raw: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      detail: null,
      notFound: false
    };
  },
  watch: {
    id: {
      handler(id) {
        this.getData(id);
      },
      immediate: true
    }
  },
  methods: {
    getData(id) {
      email.get(id).then(res => {
        if (res.exists) {
          this.detail = res.data();
        } else {
          this.notFound = true;
        }
      });
    }
  }
};
</script>

<style>
</style>
